import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import { EWorkshopPartType, EWorkshopType } from 'data/types/generic.types';
import {
  TAllAttendanceListDataDB,
  TAllAttendanceListDataUI,
  TAttendanceDB,
  TAttendanceUI,
  TAttendanceUpdateDB,
  TAttendanceUpdateUI,
  TChildCareRequirementsDB,
  TChildCareRequirementsUI,
  TGetChildCareDataDB,
  TGetChildCareDataUI,
  TGlobalChildcareCountsDB,
  TGlobalChildcareCountsUI,
  TRegistrantDB,
  TRegistrantUI,
  TSessionAttendanceListDataDB,
  TSessionAttendanceListDataUI,
  TSessionSubmitDB,
  TSessionSubmitUI,
} from 'data/types/session.types';
import {
  dateConverter,
  dateShortenFormat,
  dateTimeFormatUI,
} from 'helpers/utils';

export const getAttendancesConvertor: TFetchConverter<
  TAttendanceUI,
  TAttendanceDB
> = {
  fromDb: data => {
    return {
      isAttended: data.isAttended,
      attendanceId: data.attendanceId,
      kitCount: data.kitCount,
    };
  },
};

export const getRegistrantConverter = (
  workshopType: EWorkshopType,
): TFetchConverter<
  TRegistrantUI & { workshopId: string; isSessionStarted: boolean },
  TRegistrantDB & { workshopId: string; isSessionStarted: boolean }
> => ({
  fromDb: data => {
    const childrenNames = (data.childrenNames || []).join(', '); // Convert the array to a string

    return {
      workshopId: data.workshopId,
      kitsOrdered: data.kitsOrdered,
      language: data.language,
      lastName: data.lastName,
      orientationKitRequested: data.orientationKitRequested,
      firstName: data.firstName,
      partType: data.partType,
      ageGroup: data.ageGroup,
      materialsLanguage: data.materialsLanguage,
      attendance: getAttendancesConvertor.fromDb(data.attendance),
      registrantId: data.registrantId,
      registrationDate: dateConverter(data.registrationDate, dateShortenFormat),
      childrenNames,
      orientationKitReceived: data.orientationKitReceived,
      workshopClassId: data.workshopClassId,
      showOrientationKit:
        data.partType === EWorkshopPartType.PART_ONE &&
        workshopType === EWorkshopType.READY_FOR_KINDERGARTEN,
      globalSearchString: data.firstName
        .concat(data.lastName)
        .concat(childrenNames)
        .toLowerCase(),
      allowedTakePicture: data.allowedTakePicture,
      isSessionStarted: data.isSessionStarted,
    };
  },
});

export const getSessionAttendanceListDataConverter: TFetchConverter<
  TSessionAttendanceListDataUI,
  TSessionAttendanceListDataDB
> = {
  fromDb: data => {
    const isCCP = [
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1,
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2,
      EWorkshopType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS,
    ].includes(data.workshopType);

    return {
      language: data.language,
      hostName: data.hostName,
      workshopType: data.workshopType,
      ageGroup: data.ageGroup,
      workshopClassId: data.workshopClassId,
      attendanceType: data.attendanceType,
      sessionId: data.sessionId,
      workshopPartType: data.workshopPartType,
      sessionDate: dateConverter(data.sessionDate, dateTimeFormatUI),
      registrants: (data.registrants || []).map(registrant =>
        getRegistrantConverter(data.workshopType).fromDb({
          ...registrant,
          workshopId: data.workshopId,
          isSessionStarted: data.isSessionStarted,
        }),
      ),
      isSessionStarted: data.isSessionStarted,
      isCCP,
      workshopId: data.workshopId,
    };
  },
};

export const getAllAttendanceListDataConverter: TFetchConverter<
  TAllAttendanceListDataUI,
  TAllAttendanceListDataDB
> = {
  fromDb: data => {
    const isCCP = [
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1,
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2,
      EWorkshopType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS,
    ].includes(data.workshopType);

    return {
      workshopId: data.workshopId,
      hostName: data.hostName,
      workshopType: data.workshopType,
      sessionDate: dateConverter(data.sessionDate, dateTimeFormatUI),
      registrants: (data.registrants || []).map(registrant =>
        getRegistrantConverter(data.workshopType).fromDb({
          ...registrant,
          workshopId: data.workshopId,
          isSessionStarted: data.isSessionStarted,
        }),
      ),
      isSessionStarted: data.isSessionStarted,
      attendanceType: data.attendanceType,
      isCCP,
    };
  },
};

export const getChildCareCountConverter: TFetchConverter<
  TGlobalChildcareCountsUI,
  TGlobalChildcareCountsDB
> = {
  fromDb: data => {
    return {
      name: data.ageGroup,
      value: data.count,
    };
  },
};

export const getChildCareRequirementsConverter: TFetchConverter<
  TChildCareRequirementsUI,
  TChildCareRequirementsDB
> = {
  fromDb: data => {
    return {
      childcareRequirements: (data.childcareRequirements || []).map(item =>
        getChildCareCountConverter.fromDb(item),
      ),
      firstName: data.firstName,
      lastName: data.lastName,
      registrantId: data.registrantId,
      workshopClassId: data.workshopClassId,
    };
  },
};

export const getChildCareListDataConverter: TFetchConverter<
  TGetChildCareDataUI,
  TGetChildCareDataDB
> = {
  fromDb: data => {
    return {
      workshopId: data.workshopId,
      hostName: data.hostName,
      workshopType: data.workshopType,
      location: data.location,
      globalChildcareCounts: (data.globalChildcareCounts || []).map(item =>
        getChildCareCountConverter.fromDb(item),
      ),
      childcareRequirements: (data.childcareRequirements || []).map(item =>
        getChildCareRequirementsConverter.fromDb(item),
      ),
    };
  },
};

export const submitSessionConverter: TCreateConverter<
  TSessionSubmitUI,
  TSessionSubmitDB
> = {
  toDb: data => {
    return {
      sessionId: data.sessionId,
      workshopClassId: data.workshopClassId,
    };
  },
};
export const updateAttendanceConverter: TCreateConverter<
  TAttendanceUpdateUI,
  TAttendanceUpdateDB
> = {
  toDb: data => {
    // reset kit count and orientationKitReceived if the registrant is not attended
    const kitCount = data.isAttended ? data.kitCount || 0 : 0;
    const orientationKitReceived = data.isAttended
      ? data.orientationKitReceived
      : false;
    return {
      attendanceId: data.attendanceId,
      isAttended: data.isAttended,
      registrantId: data.registrantId,
      workshopClassId: data.workshopClassId,
      kitCount,
      orientationKitReceived,
      workshopId: data.workshopId,
    };
  },
};
